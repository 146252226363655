import { Grid } from "@mui/material";
import TenantTable from "./TenantTable";

// ==============================|| TENANT - DEFAULT ||============================== //

const TenantDefault = () => {
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} md={12} lg={12}>
                <TenantTable />
            </Grid>
        </Grid>
    );
};

export default TenantDefault;

