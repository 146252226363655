import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import CloudIcon from '@mui/icons-material/Cloud';
import ErrorIcon from '@mui/icons-material/Error';
import { getCardColor } from "../../utils/colorUtils";

const StatusCard = styled(Box)(({ theme, size }) => ({
    display: 'flex',
    borderRadius: theme.borderRadius.sm,
    height: size === 'small' ? 35 : 50,  
    width: '100%',
    overflow: 'hidden',
    boxShadow: theme.customShadows.z1,
    ':hover': {
        boxShadow: theme.customShadows.z1
    },
}));

const ColoredSection = styled(Box)(({ theme, bgcolor }) => ({
    width: '75%',
    backgroundColor: bgcolor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: theme.borderRadius.sm,
    borderBottomLeftRadius: theme.borderRadius.sm,
}));

const IconSection = styled(Box)(({ theme, size }) => ({
    width: '25%',
    backgroundColor: getCardColor(theme.palette.mode === 'light'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: theme.borderRadius.sm,
    borderBottomRightRadius: theme.borderRadius.sm,
    '& .MuiSvgIcon-root': { 
        fontSize: size === 'small' ? '1.2rem' : '1.5rem',
    },
    '& .MuiCircularProgress-root': { 
        width: size === 'small' ? '1.2rem' : '1.5rem',
        height: size === 'small' ? '1.2rem' : '1.5rem',
    }
}));

const SyncStatusCard = ({ status, size = 'normal' }) => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';

    const getColor = () => {
        if (status === 'None') return theme.palette.grey[300];
        if (status === 'Google' || status === 'Microsoft' || status === "Other") return `#74E1BF`;
        if (status === 'Error') return 'error.main';
        return getCardColor(lightMode);
    };

    const getText = () => {
        if (status === 'None') return 'no sync';
        if (status === 'Google' || status === 'Microsoft' || status === "Other") return 'active';
        if (status === 'Error') return 'error';
        return 'loading';
    };

    const getIcon = () => {
        switch (status) {
            case 'Google':
                return <GoogleIcon />;
            case 'Microsoft':
                return <MicrosoftIcon />;
            case 'Other':
                return <CloudIcon />;
            case 'Loading':
                return <CircularProgress />;
            case 'None':
                return <CloudIcon sx={{ color: lightMode ? theme.palette.grey[500] : "#FFF" }} />;
            case 'Error':
                return <ErrorIcon />;
            default:
                return <ErrorIcon />;
        }
    };

    return (
        <StatusCard size={size}>
            <ColoredSection bgcolor={getColor()}>
                <Typography
                    variant={size === 'small' ? 'h5' : 'h3'}
                    color="text.primary"
                >
                    {getText().toUpperCase()}
                </Typography>
            </ColoredSection>
            <IconSection size={size}>
                {getIcon()}
            </IconSection>
        </StatusCard>
    );
};

SyncStatusCard.propTypes = {
    status: PropTypes.oneOf(['Google', 'Microsoft', 'Other', 'Loading', 'Error', 'None']).isRequired,
    size: PropTypes.oneOf(['small', 'normal'])  
};

export default SyncStatusCard;