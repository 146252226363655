import { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Search from "./Search";
import AddTenancyModal from "../../../../pages/tenancies/AddTenancyModal";
import ProfileTab from "./Profile/ProfileTab";
import { useTheme } from "@mui/material/styles";
import Watermark from "../../../../components/Watermark";
import { saveThemeMode } from "../../../../themes/themeColourManager"
import { useThemeContext } from '../../../../themes/ThemeContext';
import { getTextColor } from '../../../../utils/colorUtils';
import ThemeToggle from "../../../../components/ThemeToggle";


const HeaderContent = ({ drawerOpen }) => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const { themeMode, updateThemeMode } = useThemeContext();
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleThemeModeToggle = () => {
        const newMode = lightMode ? 'dark' : 'light';
        updateThemeMode(newMode);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    // Calculate the left position of the header watermark based on the drawer's state
    const watermarkLeftPosition = drawerOpen ? 25 : 100;

    return (
        <>
            {matchDownSM && (
                <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                    <ProfileTab open={true} />
                </Box>
            )}
            {!matchDownSM && (
                <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* Dev Watermark 
                        <Watermark text="BETA" left={watermarkLeftPosition} />
                    */}

                    {/*TODO: This is a temporary solution. Need to refactor this*/}
                    {user?.role === "Administrators"
                        && (window.location.pathname === "/settings" || window.location.pathname === "/reports" ||
                        window.location.pathname === "/on-demand" || window.location.pathname === "/users" ||
                        window.location.pathname === "/packs" || window.location.pathname === "/tenants" 
                    )
                        && (
                            <>
                                <Search />
                                <Tooltip title="Add Tenancy" placement="bottom" arrow>
                                    <IconButton
                                        disableRipple
                                        aria-label="add tenancy"
                                        onClick={handleOpenModal}
                                        edge="start"
                                        sx={{ bgcolor: "primary.main", ml: { xs: 0, lg: 1 } }}
                                    >
                                    <AddIcon sx={{ color: getTextColor(theme.palette.primary.main) }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    {/* Dark Mode Toggle Button */}
                    <ThemeToggle />
                    {/*user?.role === "" && (
                        <ProfileTab open={true} />
                    )*/}
                </Box>
            )}
            {open && (
                <AddTenancyModal
                    open={open}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default HeaderContent;
