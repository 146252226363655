import React, { useCallback, useState, useEffect } from "react";
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography, Tabs, Tab } from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import { useNavigate } from "react-router-dom";
import { getCardColor } from "../../utils/colorUtils";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "../../themes/ThemeContext";
import { useLazyGetTinyJwtTokenQuery } from "../../store/services/simulation-service";
import { useSaveUniversityCourseSettingsMutation, useGetUniversityCourseSettingsQuery } from "../../store/services/university-service";
import { Editor } from "@tinymce/tinymce-react";
import ColorPicker from "../../components/CustomTemplateColorPicker";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

CustomEmailTemplate.propTypes = {
    data: PropTypes.object
};

export default function CustomEmailTemplate(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const lightMode = theme.palette.mode === 'light';
    const storedColors = localStorage.getItem('themeColors');
    const primaryColor = storedColors ? JSON.parse(storedColors).primary : theme.palette.primary.main;
    const secondaryColor = storedColors ? JSON.parse(storedColors).secondary : theme.palette.secondary.main;
    const tertiaryColor = storedColors ? JSON.parse(storedColors).warning : theme.palette.warning.main;

    const { tenancyId } = useSelector((state) => state.tenancy);

    // Add the query to fetch settings
    const { data: settingsData, isLoading } = useGetUniversityCourseSettingsQuery({
        tenancyId
    }, {
        skip: !tenancyId
    });

    const [editorContent, setEditorContent] = useState("");
    const [processedEditorContent, setProcessedEditorContent] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const [formData, setFormData] = useState({
        tenancyId: tenancyId,
        templateName: "",
        displayName: "",
        courseEmailSubject: "",
        reminderEmailSubject: "",
        courseBodyText: "",
        reminderBodyText: "",
        courseButtonText: "",
        reminderButtonText: "",
        courseButtonStyle: "",
        reminderButtonStyle: "",
        inviteMessage: "Hello {firstName}, you have been sent {courseName}",
        reminderMessage: "Hello {firstName}, you have {courseName} outstanding.",
        content: "",
        colors: {
            primary: primaryColor,
            secondary: secondaryColor,
            tertiary: tertiaryColor
        }
    });

    const [saveSettings] = useSaveUniversityCourseSettingsMutation();

    useEffect(() => {
        // Always update preview when tab changes or when messages change
        const messageToUse = activeTab === 0 ? formData.inviteMessage : formData.reminderMessage;
        if (editorContent) {
            const processed = editorContent.replace('{mainText}', messageToUse || '');
            setProcessedEditorContent(processed);
        }
    }, [activeTab, formData.inviteMessage, formData.reminderMessage, editorContent]);

    // Load initial data and set editor content
    useEffect(() => {
        if (settingsData) {
            // Update form data with all fields from settingsData
            setFormData(prevFormData => ({
                ...prevFormData,
                ...settingsData,
                tenancyId: settingsData.tenancyId,
                // Ensure we're explicitly setting these fields
                content: settingsData.content || '',
                courseBodyText: settingsData.courseBodyText || ''
            }));

            // Set the editor content - prioritize content field
            const contentToUse = settingsData.content || settingsData.courseBodyText || '';
            setEditorContent(contentToUse);

            // Update the preview
            const messageToUse = activeTab === 0 ? settingsData.inviteMessage : settingsData.reminderMessage;
            const processed = contentToUse.replace('{mainText}', messageToUse || '');
            setProcessedEditorContent(processed);
        }
    }, [settingsData, activeTab]);

    const handleInputChange = (field) => (event) => {
        const newValue = event.target.value;
        setFormData(prev => ({
            ...prev,
            [field]: newValue
        }));

        // Update preview for message changes
        if ((field === 'inviteMessage' && activeTab === 0) ||
            (field === 'reminderMessage' && activeTab === 1)) {
            const processed = editorContent.replace('{mainText}', newValue);
            setProcessedEditorContent(processed);
        }
    };

    const handleEditorChange = (content, editor) => {
        // Get the raw HTML content
        const rawHtml = editor.getContent();

        setEditorContent(rawHtml);
        setFormData(prev => ({
            ...prev,
            content: rawHtml,
            courseBodyText: rawHtml
        }));

        // Update preview
        const messageToUse = activeTab === 0 ? formData.inviteMessage : formData.reminderMessage;
        const processed = rawHtml.replace('{mainText}', messageToUse || '');
        setProcessedEditorContent(processed);
    };

    const handleColorChange = (colorType) => (color) => {
        setFormData(prev => ({
            ...prev,
            colors: {
                ...prev.colors,
                [colorType]: color
            }
        }));
    };

    const handleSave = async () => {
        if (!formData.tenancyId) {
            console.error('No tenancyId available');
            return;
        }

        setIsSaving(true);
        try {
            const settingsData = {
                ...formData,
                content: editorContent,
                courseBodyText: editorContent
            };

            await saveSettings({
                tenancyId: formData.tenancyId,
                settingsData
            }).unwrap();
            navigate(-1);
        } catch (error) {
            console.error('Failed to save settings:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const buttonStyles = ["Long", "Short", "Rounded", "Square"];

    const [triggerJwtToken] = useLazyGetTinyJwtTokenQuery();

    const editorStyle = `
        body { 
            background-color: ${lightMode ? 'white' : theme.palette.black.main}; 
            color: ${lightMode ? 'black' : theme.palette.grey[50]}; 
        }
    `;

    const tinydriveTokenProvider = useCallback((callback) => {
        const getToken = async () => {
            try {
                const response = await triggerJwtToken().unwrap();
                if (response) {
                    callback(response);
                } else {
                    console.warn("JWT token not available in the response");
                    callback(null);
                }
            } catch (error) {
                console.error("Error fetching JWT token:", error);
                callback(null);
            }
        };

        getToken();
    }, [triggerJwtToken]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(location.from ? location.from : -1)}
                >
                    Back
                </Button>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                    mt: 3
                }}>
                    <Typography variant="h2">{formData.templateName}</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="outlined"
                            color="black"
                            onClick={() => navigate(location.from ? location.from : -1)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleSave}
                            disabled={isSaving}
                        >
                            {isSaving ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                        >
                            Send Example
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
                <Grid container spacing={2} sx={{ flexGrow: 1, m: 0 }}>
                    <Grid item xs={12} md={6} lg={5} sx={{ height: '100%', p: 2 }}>
                        <MainCard
                            content={true}
                            boxShadow={true}
                            border={false}
                            sx={{
                                height: '100%',
                                bgcolor: getCardColor(lightMode),
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'auto'
                            }}
                        >
                            <Typography variant="h2" sx={{mb:2}}>Settings</Typography>
                            <TextField
                                label="Template Name"
                                value={formData.templateName}
                                onChange={handleInputChange('templateName')}
                                required={false}
                                size="medium"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Display Name"
                                value={formData.displayName}
                                onChange={handleInputChange('displayName')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Email Subject"
                                value={formData.courseEmailSubject}
                                onChange={handleInputChange('courseEmailSubject')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Invite Message"
                                value={formData.inviteMessage}
                                onChange={handleInputChange('inviteMessage')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Reminder Message"
                                value={formData.reminderMessage}
                                onChange={handleInputChange('reminderMessage')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Button Text"
                                value={formData.courseButtonText}
                                onChange={handleInputChange('courseButtonText')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Autocomplete
                                value={formData.courseButtonStyle}
                                onChange={(_, newValue) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        courseButtonStyle: newValue
                                    }));
                                }}
                                options={buttonStyles}
                                renderInput={(params) => <TextField {...params} label="Button Style" />}
                                sx={{ mb: 2 }}
                            />
                            <Editor
                                apiKey="j2g2hy77ifpdh2oe7hfarnk6vvls82qlv49guynyzw6d22ha"
                                value={editorContent}
                                onEditorChange={handleEditorChange}
                                init={{
                                    skin: lightMode ? 'oxide' : 'oxide-dark',
                                    content_css: lightMode ? 'default' : 'dark',
                                    content_style: editorStyle,
                                    plugins: [
                                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                                        "searchreplace", "visualblocks", "code", "fullscreen",
                                        "insertdatetime", "media", "table", "code", "help", "wordcount", "template", "tinydrive"
                                    ],
                                    images_file_types: "jpg,png,jpeg,webp",
                                    tinydrive_token_provider: tinydriveTokenProvider,
                                    toolbar:
                                        "template image | code | undo redo | fontselect fontsize fontsizeselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                                    templates: [
                                        {
                                            title: "User name",
                                            description: "Insert the users full name",
                                            content: "{user.name}"
                                        }
                                    ],
                                    height: 500,
                                    promotion: false,
                                    file_picker_types: 'media image'
                                }}
                            />
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} sx={{ height: '100%', p: 2 }}>
                        <MainCard
                            content={true}
                            boxShadow={true}
                            border={false}
                            sx={{
                                height: '100%',
                                bgcolor: getCardColor(lightMode),
                                display: 'flex',
                                flexDirection: 'column',
                                '& > .MuiCardContent-root': {
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }
                            }}
                        >
                            <Typography variant="h2" sx={{mb:2}}>Preview</Typography>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                <Tabs value={activeTab} onChange={handleTabChange}>
                                    <Tab label="Invite Email" />
                                    <Tab label="Reminder Email" />
                                </Tabs>
                            </Box>
                            <iframe
                                srcDoc={processedEditorContent}
                                style={{
                                    border: 'none',
                                    width: '100%',
                                    height: '100%',
                                    flexGrow: 1
                                }}
                                title="Email Preview"
                                sandbox="allow-same-origin allow-scripts"
                            />
                        </MainCard>
                    </Grid>
                </Grid>
            </Box>

            {/*<Box sx={{ p: 2 }}>*/}
            {/*    <Grid container>*/}
            {/*        <Grid item xs={12} md={6} lg={5}>*/}
            {/*            <MainCard*/}
            {/*                content={true}*/}
            {/*                boxShadow={true}*/}
            {/*                border={false}*/}
            {/*                sx={{*/}
            {/*                    height: '100%',*/}
            {/*                    bgcolor: getCardColor(lightMode),*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                /!*<Box sx={{*!/*/}
            {/*                /!*    display: 'flex',*!/*/}
            {/*                /!*    alignItems: 'center',*!/*/}
            {/*                /!*    gap: 14*!/*/}
            {/*                /!*}}>*!/*/}
            {/*                /!*    <ColorPicker*!/*/}
            {/*                /!*        label="Primary"*!/*/}
            {/*                /!*        color={formData.colors.primary}*!/*/}
            {/*                /!*        onChange={handleColorChange('primary')}*!/*/}
            {/*                /!*    />*!/*/}
            {/*                /!*    <ColorPicker*!/*/}
            {/*                /!*        label="Secondary"*!/*/}
            {/*                /!*        color={formData.colors.secondary}*!/*/}
            {/*                /!*        onChange={handleColorChange('secondary')}*!/*/}
            {/*                /!*    />*!/*/}
            {/*                /!*    <ColorPicker*!/*/}
            {/*                /!*        label="Tertiary"*!/*/}
            {/*                /!*        color={formData.colors.tertiary}*!/*/}
            {/*                /!*        onChange={handleColorChange('tertiary')}*!/*/}
            {/*                /!*    />*!/*/}
            {/*                /!*</Box>*!/*/}
            {/*            </MainCard>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
        </Box>
    );
}