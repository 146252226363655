import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Grid, Typography, Button, Divider, IconButton, Autocomplete, Tooltip, TextField, CircularProgress, Snackbar, Alert, Modal, Switch } from '@mui/material';
import ContentPackTable from "./ContentPackTable";
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { getCardColor, getTextColor } from "../../utils/colorUtils";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useGetTenancyByIdQuery } from "../../store/services/tenancy.service";
import { useGetContentPacksQuery } from "../../store/services/university-service";
import { useUpdateTenancyMutation } from "../../store/services/tenancy.service";
import styled from "styled-components";
import ContentPackPreview from "./ContentPackPreview";
import LoadingSwitch from "../../components/LoadingSwitch";
import { ModalProvider } from '../../components/Modal/ModalContext';
import ContentPackStatusCard from '../../components/cards/ContentPackStatusCard';
import HelpIcon from "@mui/icons-material/HelpOutline";
import ConfirmationDialog from "../../utils/confirmationDialog";
import { useNavigate } from 'react-router-dom';
import BlockingModal from '../../components/Modal/BlockingModal';
import {
    useGetUniversityCourseSettingsQuery,
} from "../../store/services/university-service";





const ButtonContainer = styled.div`
    margin-top: 5px;
`;

const ContentPacks = () => {
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [localTenancyData, setLocalTenancyData] = useState();
    const [updateTenancy] = useUpdateTenancyMutation();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewContentPackId, setPreviewContentPackId] = useState(false);
    const [isContentPackEnabled, setIsContentPackEnabled] = useState(false);
    const [isPackSettingLoading, setIsPackSettingLoading] = useState(true);
    const [isUpdatingContentPack, setIsUpdatingContentPack] = useState(false);
    const [coursePreviewOpen, setCoursePreviewOpen] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Add new state for confirmation dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogCancelAction, setDialogCancelAction] = useState(null);

    // New state to store pending content pack change
    const [pendingContentPack, setPendingContentPack] = useState(null);


    const { data: tenancyData, isLoading: tenancyLoading, error: tenancyError } = useGetTenancyByIdQuery({ tenancyId });
    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading,
        refetch: refetchUniversitySettings
    } = useGetUniversityCourseSettingsQuery({ tenancyId: tenancyId });

    const { data: contentPacksCustom, isLoading: contentPacksCustomLoading, error: contentPacksCustomError } = useGetContentPacksQuery(
        {
            perPage: 100,
            page: 0,
            searchTerm: '',
            tenancyId: tenancyData?.tenancyId
        },
        { skip: !tenancyData }
    );

    const { data: contentPacksPreset, isLoading: contentPacksPresetLoading, error: contentPacksPresetError } = useGetContentPacksQuery(
        {
            perPage: 100,
            page: 0,
            searchTerm: '',
            tenancyId: 0
        },
        { skip: !tenancyData }
    );

    const contentPacks = [...(contentPacksCustom || []), ...(contentPacksPreset || [])];
    const selectedContentPack = contentPacks.find(pack => pack.internalId === tenancyData.contentPackId) || null;



    const handleSave = (updatedData) => {
        setIsUpdatingContentPack(true);
        updateTenancy({ data: updatedData, tenancyId: updatedData.tenancyId })
            .unwrap()
            .then(() => {
                setSnackbarMessage("Content pack settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.log("Error updating content pack settings:", error);
                setSnackbarMessage("Error updating content pack settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            })
            .finally(() => {
                setIsUpdatingContentPack(false);
            });
    };

    const handleContentPackChange = (event, newValue) => {
        if (newValue) {
            setPendingContentPack(newValue);
            setDialogTitle("Set content pack");
            setDialogContent(`Are you sure you want to set the active content pack to "${newValue?.contentPackName}"`);
            setDialogCancelAction(() => () => {
                setPendingContentPack(null);
                if (selectedContentPack) {
                    setLocalTenancyData({
                        ...tenancyData,
                        contentPackId: selectedContentPack.internalId
                    });
                } else {
                    setIsContentPackEnabled(false);
                }
            });
            setDialogAction(() => () => {
                const updatedData = {
                    ...tenancyData,
                    contentPackId: newValue.internalId
                };
                setLocalTenancyData(updatedData);
                handleSave(updatedData);
                setPendingContentPack(null);
            });
            setDialogOpen(true);
        }
    };

    const handleToggleEnabled = (checked) => {
        if (checked) {
            setIsContentPackEnabled(true);
        } else if (selectedContentPack) {
            setDialogTitle("Remove content pack");
            setDialogContent("Are you sure you want to remove the active content pack? This will stop content pack sends for all users");
            setDialogCancelAction(() => () => {
                setIsContentPackEnabled(true);
            });
            setDialogAction(() => () => {
                const updatedData = {
                    ...tenancyData,
                    contentPackId: null
                };
                setIsContentPackEnabled(false);
                setLocalTenancyData(updatedData);
                handleSave(updatedData);
            });
            setDialogOpen(true);
        } else {
            setIsContentPackEnabled(false);
        }
    };

    const handlePreview = (contentPackId) => {
        setPreviewContentPackId(contentPackId);
        setPreviewModalOpen(true);
    };

    const handleClosePreviewModal = () => {
        if (!coursePreviewOpen) {
            setPreviewModalOpen(false);
            setPreviewContentPackId(null);
        }
    };
    useEffect(() => {
        if (tenancyData) {
            setLocalTenancyData(tenancyData);
            setIsContentPackEnabled(!!tenancyData.contentPackId);
            setIsPackSettingLoading(false);
        }
    }, [tenancyData]);
    
    const isPacksListLoading = contentPacksCustomLoading || contentPacksPresetLoading;
    const isPacksListError = contentPacksCustomError || contentPacksPresetError;
    const isPacksDataLoading = (tenancyLoading || isPacksListLoading || isPackSettingLoading)

    const getContentPackCardStatus = () => {
        if (isPacksListError) return 'Error';
        if (isPacksDataLoading) return 'Loading';
        if (selectedContentPack) return 'On';
        return 'Off';
    }

    /*
    if (universitySettings?.allowUnauthorisedTakeCourse) {
        return <BlockingModal />;
    }
    */


    return (
        <>
            <Grid
                container
                rowSpacing={4.5}
                columnSpacing={2.75}
                alignItems="center"
                sx={{
                    minWidth: isContentPackEnabled ? 900 : 550, 
                    flexWrap: 'nowrap' 
                }}
            >
                <Grid
                    item
                    xs={2}
                    sx={{
                        minWidth: 200,
                        flexShrink: 0,
                        mb:3
                    }}
                >
                    <ContentPackStatusCard status={getContentPackCardStatus()} />
                </Grid>
                <Grid
                    item
                    xs={isContentPackEnabled ? 8 : 4}
                    sx={{
                        minWidth: isContentPackEnabled ? 650 : 350,
                        flexShrink: 0,
                        mb: 3
                    }}
                >
                    <MainCard
                        content
                        boxShadow
                        border={false}
                        sx={{
                            bgcolor: getCardColor(lightMode),
                            display: "flex",
                            flexDirection: "column",
                            height: 50,
                            justifyContent: "center",
                            
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                            {(isPacksListLoading || isPackSettingLoading) && (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                    <Typography variant="h6">Loading content packs list...</Typography>
                                </Box>
                            )}

                            {isPacksListError && !isPacksListLoading && !isPackSettingLoading && (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                    <Typography variant="h6">Error fetching current content pack.</Typography>
                                </Box>
                            )}

                            {!isPacksListLoading && !isPackSettingLoading && !isPacksListError && (
                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        
                                    }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="The selected content pack will be sent to all new users">
                                                <IconButton>
                                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="h5" sx={{ mr: 2 }}>
                                                Select a content pack
                                                </Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <LoadingSwitch
                                                checked={isContentPackEnabled}
                                                onChange={() => {
                                                    const newValue = !isContentPackEnabled;
                                                    handleToggleEnabled(newValue);
                                                }}
                                                disabled={isPacksListLoading || isPackSettingLoading}
                                                loading={isUpdatingContentPack}
                                                inputProps={{ 'aria-label': 'content pack toggle' }}
                                            />
                                            {isContentPackEnabled && (
                                                <Autocomplete
                                                    key={isContentPackEnabled ? 'enabled' : 'disabled'} // Force recreate when toggled
                                                    value={selectedContentPack}
                                                    onChange={handleContentPackChange}
                                                    options={contentPacks}
                                                    getOptionLabel={(option) => option?.contentPackName || ''}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option?.internalId === value?.internalId
                                                    }
                                                    loading={contentPacksCustomLoading || contentPacksPresetLoading}
                                                    sx={{ width: 300 }}
                                                    clearOnBlur={false}
                                                    onClose={() => setPendingContentPack(null)}
                                                    clearText="Remove content pack"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Select a content pack"
                                                            size="small"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {(contentPacksCustomLoading || contentPacksPresetLoading) ? (
                                                                            <CircularProgress color="inherit" size={20} />
                                                                        ) : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                            
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </MainCard>
                </Grid>
                <Grid
                    item
                    xs={selectedContentPack ? 2 : 0}
                    sx={{
                        minWidth: 100,
                        flexShrink: 0,
                        mb: 3
                    }}
                >
                {selectedContentPack && (
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            color="warning"
                            size="medium"
                            sx={{
                                width: "100%",
                                whiteSpace: "nowrap",
                                ...(lightMode
                                    ? {}
                                    : {
                                        '&.MuiButton-containedPrimary': {
                                            bgcolor: theme.palette.primary.main,
                                            color: getTextColor(theme.palette.primary.main),
                                            '&:hover': {
                                                bgcolor: "#69686b",
                                            },
                                        },
                                    }),
                            }}
                            onClick={() => handlePreview(selectedContentPack.internalId)}
                        >
                            Preview active
                        </Button>
                        </ButtonContainer>)}
                </Grid>
        </Grid>
        <Box sx={{ width: '100%' }}>
            <ContentPackTable type={"custom"} />
        </Box>
            <ModalProvider>
                <Modal
                    open={previewModalOpen}
                    onClose={handleClosePreviewModal}
                    aria-labelledby="simulation-preview-title"
                    aria-describedby="simulation-preview-description"
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '8px',
                        width: '600px',
                        height: '90vh',
                        p: 2,
                        bgcolor: theme.palette.background.default,
                        boxShadow: 24,
                        maxHeight: '90vh',
                        overflow: 'hidden',
                        visibility: coursePreviewOpen ? 'hidden' : 'visible'
                    }}>
                        {previewModalOpen && previewContentPackId && (
                            <ContentPackPreview
                                contentPackId={previewContentPackId}
                                setCoursePreviewOpen={setCoursePreviewOpen}
                            />
                        )}
                    </Box>
                </Modal>
            </ModalProvider>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}>
            <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled"
                sx={{ width: "100%" }}>
                {snackbarMessage}
            </Alert>
            </Snackbar>
        <ConfirmationDialog
            open={dialogOpen}
            title={dialogTitle}
            content={dialogContent}
            onConfirm={() => {
                setDialogOpen(false);
                dialogAction?.();
            }}
            onCancel={() => {
                setDialogOpen(false);
                dialogCancelAction?.();
            }}
        />
        </>
    );
}

export default ContentPacks
